
	import {
		ElMessage
	} from 'element-plus'

	export default {
		data() {
			return {
				clientWidth: document.documentElement.clientWidth,
				labelPosition: 'right',
				shotinputwidth: {
					width: '280px'
				},
				form: {
					zhanghao: '',
					danwei: '',
					dianhua: '',
					lianxiren: '',
					xingbie: '',
					email: '',
					shouji: '',
					dizhi: '',
				},
				loading: false,
			}
		},
		beforeCreate() {
			this.PAGELANG = this.LANG.pages.Profile;
		},
		created() {
			window.addEventListener('resize', this.handleResize)
		},
		mounted() {
			let that = this;
			if (that.clientWidth <= 480) {
				that.labelPosition = 'top';
				that.shotinputwidth = {};
			} else {
				that.labelPosition = 'right';
				that.shotinputwidth = {
					width: '280px'
				};
			}
			
			this.refreshPageInfo();
		},
		methods: {
			handleResize() {
				this.clientWidth = document.documentElement.clientWidth;
			},
			refreshPageInfo() {
				if(this.loading) {
					ElMessage({
						message: this.LANG.system.xitongfanmang,
						type: "warning"
					})
					return;
				}
				this.loading = true;
				this.axios.get(this.actions.getuserinfo).then((response) => {
					console.log(response);
					this.loading = false;
					let uinfo = response.data;
					this.form.zhanghao = uinfo.Account;
					this.form.danwei = uinfo.Name;
					this.form.dianhua = uinfo.Phone;
					this.form.lianxiren = uinfo.Contact;
					this.form.xingbie = uinfo.Sex;
					this.form.email = uinfo.Email;
					this.form.shouji = uinfo.Mobile;
					this.form.dizhi = uinfo.Address;
				});

			},
			onSubmit() {
				/*this.axios.get("/api/api.sample.js").then((response) => {
					if (response.status == 200)
						console.log(response.data);
				})*/
				if(this.loading) {
					ElMessage({
						message: this.LANG.system.xitongfanmang,
						type: "warning"
					})
					return;
				}
				this.loading = true;
				this.axios.post(this.actions.saveuserinfo, this.form).then((response) => {
					console.log(response);
					if (response.data.retinfo == 'ok') {
						ElMessage({
							message: this.LANG.system.chenggonggengxinshuju,
							type: "success"
						});
					}
					else {
						ElMessage({
							message: this.LANG.system.gengxinshujucuowu,
							type: "error"
						});
					}
					this.loading = false;
				});
			},
			onCancel() {
				this.refreshPageInfo();
			}
		},
		watch: {
			clientWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timerw) {
					// 一旦监听到的clientWidth值改变，就将其重新赋给data里的clientWidth
					this.clientWidth = val
					this.timerw = true
					let that = this
					setTimeout(function() {
						// 打印clientWidth变化的值
						if (that.clientWidth <= 480) {
							that.labelPosition = 'top';
							that.form.shotinputwidth = {};
						} else {
							that.labelPosition = 'right';
							that.form.shotinputwidth = {
								width: '280px'
							};
						}
						console.log(that.labelPosition + '-' + that.clientWidth)
						that.timerw = false
					}, 400)
				}
			},
		}
	}
